import "../style.scss";

import { formatLongDate } from "@clipboard-health/date-time";
import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { IonContent, IonItem, IonList, IonListHeader, IonPage } from "@ionic/react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, CircularProgress, DialogContent, IconButton, Stack } from "@mui/material";
import { DEFAULT_TIMEZONE } from "@src/app/attendancePolicy/constants";
import { ShiftFilters } from "@src/app/components/shiftFilters";
import { useSession } from "@src/app/store/helperHooks";
import {
  checkRolloutMsa,
  isShiftPriorityValid,
  removeConflictingShifts,
} from "@src/app/utils/shiftHelper";
import { useWorkerPreferences } from "@src/appV2/Agents/api/useWorkerPreferences";
import { CbhFeatureFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { AppBarHeader, FullScreenDialog, PageWithHeader, SlideUpTransition } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { useGetPaginatedBookabilityStatusForShifts } from "@src/appV2/OpenShifts/ShiftAction/api/useGetBookabilityStatusForShifts";
import { useATeamStatus } from "@src/appV2/Rankings/useATeamStatus";
import { ShiftWindow } from "@src/appV2/Shifts/Shift/types";
import { WorkingShift } from "@src/appV2/Shifts/Shift/WorkingShift/WorkingShift";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { HCF_PROFILE_SOURCE } from "@src/constants";
import { Facility, LegacyShift, Shift } from "@src/lib/interface";
import { parseISO } from "date-fns";
import { intersection, orderBy } from "lodash";
import moment from "moment-timezone";
import { FC, Fragment, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { USER_EVENTS } from "../../../constants/userEvents";
import { instantPayStatus, isHCPInstantPayAllowed } from "../api";
import { UpdateShiftListFn } from "../custom-hooks/interfaces";
import { useShiftReqTag } from "../custom-hooks/useShiftReqTag";
import { FacilityDetails } from "../facilityDetails";
import { ShiftListProps } from "../model";
import { ShiftItem } from "../shiftItem";
import { ShiftPaginationLoader } from "../shiftItem/components/ShiftPagination";

export const shouldShiftLeadToMyDetailsPage = (shift: Shift, userId: string): boolean => {
  const isAssignedToCurrentUser = shift.agentId === userId;
  const isIPAndUserClockedIn = shift.isInstantPay && shift.clockInOut?.start;
  const hasShiftEnded = moment(shift.end).isBefore(moment());
  return !!(isAssignedToCurrentUser && (isIPAndUserClockedIn || hasShiftEnded));
};

const ShiftListPage: FC<ShiftListProps> = ({
  day,
  shiftName,
  shifts,
  onClose,
  getOpenAndMyShifts,
  loadingShiftList,
  searchMode,
  distancePreference,
  qualificationPreference,
  filterDays,
  shiftSlots,
  updateShiftFields,
}) => {
  const basePath = process.env.REACT_APP_BASE_PATH ?? "/";

  const { bookingRolloutMsas } = useSession();

  const [sortedShifts, setSortedShifts] = useState<Shift[]>([]);
  const [facilityDetails, setFacilityDetails] = useState<Facility>();
  const [shiftDetails, setShiftDetails] = useState<Shift>();
  const [isHcpInstantPayEnabled, setIsHcpInstantPayEnabled] = useState(false);
  const [displayExtraTimePayCard, setDisplayExtraTimePayCard] = useState(false);

  const facilityDetailsModalState = useModalState();

  const { setFilters } = useWorkerPreferences();
  const worker = useDefinedWorker();
  const { isWorkerAteamEnabled } = useATeamStatus();

  const ldFlags = useCbhFlags();

  const { qualifications: allowedQualificationsForWorkerAvailability } = ldFlags[
    CbhFeatureFlag.ENABLE_WORKER_AVAILABILITY
  ] ?? {
    qualifications: [],
  };
  const agentAvailableQualifications = worker.licensesData?.map((item) => item.qualification) ?? [];
  const isWorkerAvailabilityEnabled =
    intersection(allowedQualificationsForWorkerAvailability, agentAvailableQualifications).length >
    0;

  useLogEffect(USER_EVENTS.VIEWED_BOOK_SHIFTS);

  const fetchHcpInstantPayStatus = async () => {
    const status = await instantPayStatus();
    setIsHcpInstantPayEnabled(isHCPInstantPayAllowed(status));
  };

  useEffect(() => {
    fetchHcpInstantPayStatus();
  }, []);

  useEffect(() => {
    // We had a odd issue where the Shift listed on the calendar was different
    // from the selected date, ticket CH-20774. We were not able to reproduce
    // the error, this loggings can help us tracking in case it happens again.
    const allShifts = [...shifts.open, ...shifts.assigned];
    allShifts.forEach((shift) => {
      const selectedDate = moment.tz(day, DEFAULT_TIMEZONE);
      const isSameDate = selectedDate.isSame(moment(shift.start), "date");
      if (!isSameDate) {
        logEvent(USER_EVENTS.SHIFT_WRONG_DATE_SLOT, {
          error: "Shift was incorrectly displayed on a different date slot in the Calendar",
          day,
          shiftId: shift._id,
          shiftStart: shift.start,
          shiftEnd: shift.end,
          agentId: shift.agentId,
        });
      }
    });
  }, [shifts.open, shifts.assigned, day]);

  const openShiftIds = sortedShifts
    .filter((shift) => isDefined(shift._id))
    .map((shift) => shift._id ?? "");

  const {
    shiftBookabilityById,
    queryResult: paginatedBookabilityStatusQueryResult,
    bookabilityLoadedShiftsCount: shiftsToRenderCount,
  } = useGetPaginatedBookabilityStatusForShifts({
    shiftIds: openShiftIds,
    agentId: worker.userId,
  });

  const {
    isLoading: isLoadingShiftBookability,
    fetchStatus: shiftBookabilityFetchStatus,
    fetchNextPage: fetchNextPageBookabilityStatus,
    isFetchingNextPage: isFetchingShiftBookabilityForNextPage,
    hasNextPage: hasNextOpenShiftPage,
  } = paginatedBookabilityStatusQueryResult;

  const shiftSortKeyTimeslotFlag = ldFlags[CbhFeatureFlag.SHIFTS_SORT_KEY_TIMESLOT];
  useEffect(() => {
    // SHIFTS_SORT_KEY_TIMESLOT == distance: sort by distance from HCP address to HCF address, closest to farthest
    // SHIFTS_SORT_KEY_TIMESLOT == pay: sort by hourly rate, highest to lowest and then by distance , closest to farthest
    const sortIdentities = [
      (shift) => (shift.priorityTill && isShiftPriorityValid(shift.priorityTill) ? 0 : 1),
      "facility.distance",
      "start",
    ];
    const sortOrders: Array<"asc" | "desc"> = sortIdentities.map((_) => "asc");

    if (shiftSortKeyTimeslotFlag === "pay") {
      sortIdentities.splice(1, 0, "finalPay");
      sortOrders.splice(1, 0, "desc");
    }

    if (isWorkerAteamEnabled) {
      sortIdentities.splice(1, 0, (shift) => (shift.window === ShiftWindow.A_TEAM ? 0 : 1));
      sortOrders.splice(1, 0, "asc");
    }

    setSortedShifts(
      orderBy(
        removeConflictingShifts(shifts.open, shifts.assigned),
        sortIdentities,
        sortOrders
      ) as Shift[]
    );
  }, [shifts.open, shifts.assigned, shiftSortKeyTimeslotFlag, isWorkerAteamEnabled]);

  const history = useHistory();
  function onClickAssignedShiftItem(shift: Shift) {
    return () => onShiftCardTapped({ shift });
  }

  const updateShiftList: UpdateShiftListFn = (shift, isInstantBooking, response) => {
    if (response && !response.isAlreadyGrabbed) {
      sortedShifts.forEach((current) => {
        if (current._id === shift._id) {
          if (isInstantBooking) {
            current.agentId = worker.userId;
          } else {
            if (checkRolloutMsa(shift as LegacyShift, bookingRolloutMsas as string[])) {
              current.agentId = worker.userId;
            } else {
              current.interested?.push(worker.userId);
            }
          }
        }
      });
      setSortedShifts(sortedShifts);
    } else {
      getOpenAndMyShifts?.();
    }
  };

  function onClickOnItemFacilityDetails(
    shift: Shift,
    isInstantBook: boolean,
    displayExtraTimePayCard = false
  ) {
    onShiftCardTapped({ shift, isInstantBook, displayExtraTimePayCard });
  }

  interface onShiftCardTappedOptions {
    shift: Shift;
    isInstantBook?: boolean;
    displayExtraTimePayCard?: boolean;
  }

  function onShiftCardTapped(options: onShiftCardTappedOptions) {
    const isInstantBook = isDefined(options.isInstantBook) ? options.isInstantBook : false;
    const displayExtraTimePayCard = isDefined(options.displayExtraTimePayCard)
      ? options.displayExtraTimePayCard
      : false;
    if (
      shouldShiftLeadToMyDetailsPage(options.shift, worker.userId) &&
      options.shift.hasSentHomeRequest === false
    ) {
      navigateToMyShiftDetails(options.shift);
    } else {
      openFacilityDetails(options.shift, isInstantBook, displayExtraTimePayCard);
    }
  }

  function navigateToMyShiftDetails(shift: Shift) {
    history.push(`/home/myShifts/${shift._id!}`);
  }

  function openFacilityDetails(
    shift: Shift,
    isInstantBook: boolean,
    displayExtraTimePayCard: boolean
  ) {
    if (isInstantBook) {
      logEvent(USER_EVENTS.VIEWED_FACILITY_PROFILE, {
        bookingType: "instant book",
      });
    } else {
      logEvent(USER_EVENTS.VIEWED_FACILITY_PROFILE, {
        bookingType: "standard",
      });
    }
    setDisplayExtraTimePayCard(displayExtraTimePayCard);
    setFacilityDetails(shift.facility);
    setShiftDetails(shift);
    facilityDetailsModalState.openModal();
  }

  function closeFacilityDetails() {
    setFacilityDetails(undefined);
    setShiftDetails(undefined);
    getOpenAndMyShifts?.();
    facilityDetailsModalState.closeModal();
  }

  const availableShiftIds = useMemo(() => {
    return sortedShifts ? sortedShifts.map((shift) => shift._id!) : [];
  }, [sortedShifts]);

  const { allowedAgentReq } = useShiftReqTag();

  return (
    <IonPage>
      <PageWithHeader
        containerVariant="without-margin"
        appBarHeader={
          <AppBarHeader
            title={formatLongDate(parseISO(day))}
            leftCta={
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="Go Back"
                title="Go Back"
                onClick={() => {
                  onClose();
                }}
              >
                <ChevronLeftIcon fontSize="large" />
              </IconButton>
            }
          />
        }
      >
        <Stack sx={{ height: "100%" }}>
          <Box
            sx={{
              paddingY: 1,
              display: "flex",
              flexGrow: 0,
              justifyContent: "center",
            }}
          >
            <ShiftFilters
              totalShifts={sortedShifts.length}
              distancePreference={distancePreference}
              qualificationPreference={qualificationPreference}
              searchMode={searchMode}
              setFilters={setFilters}
              agentLicenses={worker.licensesData}
            />
          </Box>
          {loadingShiftList ||
          (isLoadingShiftBookability && shiftBookabilityFetchStatus !== "idle") ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                flexGrow: 1,
              }}
            >
              <img
                width="250"
                height="250"
                src={`${basePath}assets/gifs/loadingAvailableShifts.gif`}
                alt="Loading available shifts"
              />
            </div>
          ) : (
            <IonContent style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
              <div className="list-title">
                <IonListHeader style={{ fontSize: "inherit" }}>
                  {shiftName.toUpperCase()} Shifts
                </IonListHeader>
              </div>
              <IonList lines="none">
                {shifts.assigned.map((shift) =>
                  shift._id && shift.agentId === worker.userId ? (
                    <WorkingShift key={shift._id} shiftId={shift._id} />
                  ) : (
                    <ShiftItem
                      key={shift._id}
                      shift={shift}
                      onFacilityDetailsClick={onClickOnItemFacilityDetails}
                      isHcpInstantPayEnabled={isHcpInstantPayEnabled}
                      onItemClick={onClickAssignedShiftItem(shift)}
                      shiftSlots={shiftSlots}
                    />
                  )
                )}
                {!sortedShifts.length && !shifts.assigned.length && (
                  <IonItem lines="none">
                    <p style={{ paddingRight: "30px", color: "#616161" }}>
                      {`${
                        isWorkerAvailabilityEnabled
                          ? "Unfortunately, this shift is no longer available."
                          : "No shifts available."
                      }`}
                    </p>
                  </IonItem>
                )}
                {sortedShifts
                  .filter((shift) => isDefined(shift._id))
                  .map((shift, shiftIndex) => {
                    const shiftBookability =
                      shiftBookabilityById[shift._id ?? ""]?.attributes.bookability;

                    if (isDefined(shiftsToRenderCount) && shiftIndex >= shiftsToRenderCount) {
                      return null;
                    }

                    return (
                      <Fragment key={shift._id}>
                        {hasNextOpenShiftPage &&
                          isDefined(fetchNextPageBookabilityStatus) &&
                          !isFetchingShiftBookabilityForNextPage &&
                          shiftIndex + 1 === shiftsToRenderCount && (
                            <ShiftPaginationLoader
                              onLoadNextPage={() => {
                                logEvent(APP_V2_USER_EVENTS.VIEWED_NEXT_OPEN_SHIFT_PAGE, {
                                  searchMode,
                                  shiftsToRenderCount,
                                });

                                fetchNextPageBookabilityStatus();
                              }}
                            />
                          )}
                        <ShiftItem
                          shift={shift}
                          updateShiftList={updateShiftList}
                          updateShiftFields={updateShiftFields}
                          onFacilityDetailsClick={onClickOnItemFacilityDetails}
                          isHcpInstantPayEnabled={isHcpInstantPayEnabled}
                          searchMode={searchMode}
                          availableShiftIds={availableShiftIds}
                          filterDistance={distancePreference}
                          filterDays={filterDays}
                          shiftSlots={shiftSlots}
                          allowedAgentReq={allowedAgentReq}
                          shiftBookability={shiftBookability}
                          isShiftBookabilityLoading={
                            isFetchingShiftBookabilityForNextPage || isLoadingShiftBookability
                          }
                        />
                      </Fragment>
                    );
                  })}
                {isFetchingShiftBookabilityForNextPage && (
                  <Stack
                    sx={{
                      alignItems: "center",
                      paddingY: 1,
                    }}
                  >
                    <CircularProgress />
                  </Stack>
                )}
              </IonList>
            </IonContent>
          )}
        </Stack>

        {isDefined(facilityDetails) ? (
          <FullScreenDialog
            modalState={facilityDetailsModalState}
            TransitionComponent={SlideUpTransition}
          >
            <DialogContent sx={{ padding: 0 }}>
              <FacilityDetails
                onClose={closeFacilityDetails}
                facility={facilityDetails}
                shift={shiftDetails}
                displayExtraTimePayCard={displayExtraTimePayCard}
                hcfProfileSource={HCF_PROFILE_SOURCE.CALENDAR}
              />
            </DialogContent>
          </FullScreenDialog>
        ) : null}
      </PageWithHeader>
    </IonPage>
  );
};

export { ShiftListPage };
